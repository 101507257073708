.review-custom {
  /* max-width: 100% !important; */
  width: 100%;
  font-size: 1.5rem;
  margin: 20px 0;
}

.review-header{
  padding: 10px 20px;
  
}

.review-header img{
  width:40px
}

.review-body{
  padding: 15px 20px;
  color: black;
}