@keyframes slide-in {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.list-item {
  animation: slide-in 1.5s forwards;
}
