.admin-login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  padding: 15px;
  margin: auto;
  height: 60vh;
  min-height: 340px;
  /* border: 2px solid #ccc; */


  transition: all 0.3s ease-in-out;
}

